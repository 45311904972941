import { SD_MODEL } from "@/enums/device";
import { useAppStore } from "@/store/app";
import { useSystemStore } from "@/store/system";
import { buildUrlParam } from "@/hooks/BuildUrlParam";
import router from "@/router";
export  async function launchCheck() {
  console.info('Launch Touch')
  const appStore = useAppStore();        // app信息
  const systemStore = useSystemStore();  // 系统信息
  const baseUrl = location.origin;
  const query = buildUrlParam({ ...router.currentRoute.value.query, token: appStore.token, sd: 2 })	// 构建url参数
   console.log(systemStore.app?.build, 'systemStore.app?.build', `${baseUrl}${query}`);
  if (systemStore.app?.build === SD_MODEL.ANDROID_H5) {
    window.open(`${baseUrl}${query}`, '_blank');
  } else {
    var e = "web+star";
    var i = `${baseUrl}${query}`
    document.querySelectorAll("#protocol-detector").forEach(function (e) {
      e.remove()
    });
    var r = document.createElement("iframe");
    r.setAttribute("id", "protocol-detector");
    r.setAttribute("src", "");
    r.setAttribute("style", "display:none !important;height:1px;width:1px;border:none;z-index:-1 !important;opacity:0 !important;visibility:hidden !important;");
    document.body.appendChild(r);
    r.contentWindow.location = e + "://" + i
  }
}
