import useCopyAccountInfo from '@/hooks/useCopyAccountInfo'
import { delay } from '@/utils/delay'
import { urlCheck } from '@/hooks/UrlCheck';
import { emitter } from "@/utils/event";
export default async (isDowloandAPk = true) => {
   const channelStore = useChannelStore();
   const apkUrl = channelStore.promotionInfo?.installUrl;
   const reg = /.+_(\d+)\.apk$/
   const id = apkUrl?.match(reg)?.[1]
   let wakeUp = false
   useCopyAccountInfo()
   location.href = `intent://open#Intent;scheme=panda_${id};end`
   // 清除定时器，如果应用成功拉起，跳转就不会触发定时器
   window.onblur = function () {
      wakeUp = true
   };
   channelStore.setInstallStatus('Installing')
   emitter.emit('pwa/updateProgress', {})
   await delay(3000)
   if (wakeUp) return true

   if (isDowloandAPk) {
      location.href = urlCheck(apkUrl);
   }

   return false
}
