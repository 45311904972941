// 获取用于广告统计的tracker参数
import { useCookies } from "@vueuse/integrations/useCookies";

export default function useGetTracker(): any {
  let tracker: null | {} = null;
  //记录tracker信息
  const cookie = useCookies();
  const fbc = cookie.get("_fbc");
  const fbp = cookie.get("_fbp");
  //@ts-ignore
  if (window.fbPixelId && fbc && fbp) {
    tracker = {
      type: "fb",
      fbc: fbc,
      fbp: fbp,
      //@ts-ignore
      pixel_id: window.fbPixelId,
    };
  }

  const urlSearchParams = new URLSearchParams(window.location.href);
  const ttclidValue = urlSearchParams.get("ttclid") || (window as any).ttclid;
  const ttp = cookie.get("_ttp");
  const ttclid = cookie.get("ttclid") || ttclidValue;
  //@ts-ignore
  if (window.ttPixelId && ttp) {
    tracker = {
      type: "tiktok",
      ttp: ttp,
      ttclid: ttclid,
      //@ts-ignore
      pixel_id: window.ttPixelId,
    };
  }

  //@ts-ignore
  if (window.kwaiId) {
    const kwaiClickId = urlSearchParams.get("kwai_click_id") || (window as any).kwai_click_id
    const clickId = urlSearchParams.get("click_id") || (window as any).click_id
    tracker = {
      type: "kwai",
      kwai_click_id: kwaiClickId || clickId || 'sFO1WRDaSjIH4w56M3PfqA'
    };
  }
  //@ts-ignore
  if (window.afId) {
    tracker = {
      type: "af",
      //@ts-ignore
      appsflyer_id: window.afId
    };
  }
  return tracker;
}
