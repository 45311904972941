import { copy } from "@/hooks/Copy";
export default async () => {
  const appStore = useAppStore();
  if (!appStore.token) return
  const acc = (await appStore.getAccount()) || ''  // 获取账号
  const pass = (await appStore.getPassword()) || '' // 获取密码
  const loginType = appStore.loginType || ''
  const token = appStore.token || ''
  const accountInfo = btoa(`loginType=${loginType}&acc=${acc}&pass=${pass}&token=${token}`)
  copy(accountInfo, false)
}
